import { Maquinas, Apontamentos } from "./../app/common/interfaceApp";
import { defineStore } from "pinia";
import { useAppStore } from "./app";

export const useAppMaquinas = defineStore({
  id: "appMaquinas",
  state: (): {
    resourceMaquinas: Maquinas[],
    showModalMaquina: boolean,
  } => ({
    resourceMaquinas: [],
    showModalMaquina: false,
  }),

  getters: {},
  actions: {
    set_tp_parada(apontamento: Apontamentos) {
      if (!apontamento) return null
      if (apontamento.tipo == 'Parada') {
        if ((apontamento.manutencao == 1) && (apontamento.setup == 0)) { return 'Manutenção'; }
        else if ((apontamento.setup == 1) && (apontamento.manutencao == 0)) { return 'Setup'; }
        else { return 'Parada'; }
      } else { return null; }
    },

    async listar_maquinas() {
      try {
        this.resourceMaquinas = [];

        const res = await frappe.call(
          "nxlite.nx_producao.page.apontamento_iot.controller.get_all_recurso",
          { limit: 15 }
        );

        res.message.forEach((maquina: any) => {
          const apontamentos: Apontamentos[] = [];

          if (Array.isArray(maquina.apontamentos)) {

            maquina.apontamentos.forEach((apontamento: Apontamentos) => {
              apontamentos.push({
                name: apontamento.name,
                nome_operador: apontamento.nome_operador,
                recurso: apontamento.recurso,
                desc_recurso: apontamento.desc_recurso,
                dt_inicio: apontamento.dt_inicio,
                dt_fim: apontamento.dt_fim,
                ordem_de_producao: apontamento.ordem_de_producao,
                status: apontamento.status,
                observacao: apontamento.observacao,
                tipo: apontamento.tipo,
                serial: apontamento.serial,
                motivo_de_parada: apontamento.motivo_de_parada,
                desc_motivo_parada: apontamento.desc_motivo_parada,
                origem_apontamento: apontamento.origem_apontamento,
                turno: apontamento.turno,
                status_sensor: apontamento.status_sensor,
                motivo_de_parada_manutencao_setup: this.set_tp_parada(apontamento),
                setup: apontamento.setup,
                manutencao: apontamento.manutencao
              });
            });
          }

          this.resourceMaquinas.push({
            id: maquina.name,
            imagem: maquina.imagem,
            leitor: maquina.leitor,
            centro_de_custo: maquina.centro_de_custo,
            nome: maquina.nome,
            operador_padrao: maquina.operador_padrao,
            tipo_apontamento: maquina.tipo_apontamento,
            apontamentos: apontamentos,
            state_sensor: maquina.state_sensor,
            dt_atualizacao_sensor: maquina.dt_atualizacao_sensor
          });

          if (useAppStore().maquina_sel.id) {
            const apontamentoAberto = maquina.apontamentos.find((apontamento: Apontamentos) => apontamento.status === "Aberto");
            if (apontamentoAberto) { useAppStore().set_apontamento_realtime(apontamentoAberto); }
            useAppStore().apontamentos_historico = apontamentos.filter((apontamento: Apontamentos) => apontamento.status !== "Aberto")
          }
        });

      } catch (error) { throw new Error(error); }
    },

    update_recurso_realtime(recurso_doc: any) {
      console.log(recurso_doc)
      const new_data = {
        id: recurso_doc.name,
        imagem: recurso_doc.imagem,
        leitor: recurso_doc.leitor,
        centro_de_custo: recurso_doc.centro_de_custo,
        nome: recurso_doc.nome,
        operador_padrao: recurso_doc.operador_padrao,
        tipo_apontamento: recurso_doc.tipo_apontamento,
        apontamentos: recurso_doc.apontamentos,
        state_sensor: recurso_doc.state_sensor
      };

      const found = this.resourceMaquinas.find((data) => data.id == recurso_doc.name);

      if (found) {
        const new_resource = this.resourceMaquinas.map((data) =>
          data.id == recurso_doc.name ? new_data : data
        );

        this.resourceMaquinas = new_resource;
      } else { this.resourceMaquinas.push(new_data) }
      // if this.maquina_sel.
    },

    update_apontamento_realtime(data) {
      if (data.recurso && useAppStore().maquina_sel.id === data.recurso.name) {
        const { apontamento } = data;

        const filteredApontamento: Apontamentos = {
          name: apontamento.name,
          nome_operador: apontamento.nome_operador,
          recurso: apontamento.recurso,
          desc_recurso: apontamento.desc_recurso,
          dt_inicio: apontamento.dt_inicio,
          dt_fim: apontamento.dt_fim,
          ordem_de_producao: apontamento.ordem_de_producao,
          status: apontamento.status,
          observacao: apontamento.observacao,
          tipo: apontamento.tipo,
          serial: apontamento.serial,
          motivo_de_parada: apontamento.motivo_de_parada,
          desc_motivo_parada: apontamento.desc_motivo_parada,
          origem_apontamento: apontamento.origem_apontamento,
          turno: apontamento.turno,
          status_sensor: apontamento.status_sensor,
          motivo_de_parada_manutencao_setup: this.set_tp_parada(apontamento),
          setup: apontamento.setup,
          manutencao: apontamento.manutencao
        };

        if (apontamento.status === 'Fechado') {
          useAppStore().apontamentos_historico = [
            filteredApontamento,
            ...useAppStore().apontamentos_historico
          ];
        } else {
          useAppStore().set_apontamento_realtime(apontamento);
        }

      }
    },
  }, persist: { enabled: true }
});
