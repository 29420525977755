<template>
    <v-toolbar :color="colorStatusApontamento" class="toolbar-realtime" height="40" id="v-toolbar_production">
        <v-row justify="space-between" class="pl-0 pr-0">
            <v-col cols="1" class="pl-0 pr-0 pt-0 pb-0">
                <v-card v-if="verify_status_sensor" :color="status_color" style="width: 100%; height: 100%;"
                    class="d-flex align-center justify-center">
                    <v-icon size="35">mdi-lan-connect</v-icon>
                </v-card>

                <v-card v-else :color="'#78909C'" style="width: 100%; height: 100%;"
                    class="d-flex align-center justify-center">
                    <v-icon size="35">mdi-lan-disconnect</v-icon>
                </v-card>
            </v-col>
            <v-col cols="11" class="pl-0 pr-0">
                <small v-if="appStore.apontamento_atual.name" class="ml-2"
                    style="font-weight: bold;font-size: 16px;">TEMPO REAL DA MÁQUINA : {{ tempExecucao
                    }} | ID : {{
                        appStore.maquina_sel.id }} | </small>
                
                
                <v-menu v-model="isMenuOpen" :location="'bottom'" class="mt-5" persistent scrim="true" height="70vh"
                    width="50%" :close-on-content-click="false">
                    
                    <template v-slot:activator="{ props }">
                        <v-btn id="btn-maquina" variant="tonal" class="ml-2" @clik="appStore().reset_app()"
                            :color="appStore.apontamento_atual.tipo == 'Produção' ? '#1B5E20' : '#E53935'"
                            style="font-weight: bold;font-size: 16px;color:white" v-bind="props" height="30">
                            {{ appStore.maquina_sel.nome }}
                        </v-btn>
                    </template>

                    <v-list class="pt-0 pb-0 mt-0">
                        <v-toolbar color="primary" class="pt-0 pb-0 mt-0">
                            <v-btn color="secondary" variant="elevated" height="60"
                                prepend-icon="mdi-arrow-left-circle" @click="isMenuOpen = false">VOLTAR</v-btn>
                            <small class="ml-4" style="font-size: 16px;">LISTA DE MAQUINAS</small>
                        </v-toolbar>

                        <v-text-field density="compact" v-model="filter_maquinas" label="FILTRAR MÁQUINAS" class="mt-2 mb-0 pl-1 pr-1 pt-0 pb-0"
                               style="width: 100%;font-weight: bold;"  variant="solo" bg-color="secondary"
                            placeholder="FILTRAR MÁQUINAS" ></v-text-field>

                        <v-list-item class="pt-0 pb-0 mt-0" v-for="(maquina, index) in resourceMaquinas" :key="index">
                            <v-row class="pt-0 pb-0 mt-0" justify="space-between">
                                <v-col cols="12" class="pt-0 pb-0 mt-0">
                                    <v-btn class="mt-0 pt-0" color="primary" height="60" @click="SelecionarMaquina(maquina)"
                                        style="width: 100%;">{{ maquina.nome
                                        }}</v-btn>
                                </v-col>
                            </v-row>
                            <v-divider :thickness="7" style="font-weight: bold;" inset color="primary"></v-divider>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <small class="ml-2" style="font-weight: bold;font-size: 16px;"> | {{ appStore.apontamento_atual.serial
                    }}</small>

                <v-btn class="ml-4" v-if="appStore.apontamento_atual.op"
                    style="font-weight: bold;font-size: 16px;color:white" @click="ShowDetalheOS" variant="tonal" height="30"
                    append-icon="mdi-folder-open">DESENHOS</v-btn>
            </v-col>
        </v-row>
    </v-toolbar>
</template>

<script>

import { useAppMaquinas } from '../../stores/appMaquinas';

import { useAppStore } from '../../stores/app';

import { CalculaTempoMaquina, colorStatusParada } from '../utils/funcoes';

import {useAppOrdemProducao} from "../../stores/appOrdemProducao";

export default {
    name: "headerApontamento",
    components: {
    },
    data() {
        return {
            tempExecucao: '00:00:00',
            isMenuOpen: false,
            time: 0,
            filter_maquinas : '',
        };
    },
    computed: {
        resourceMaquinas() {
            const filter = this.filter_maquinas.toUpperCase();
            return useAppMaquinas().resourceMaquinas.filter(maquina => 
            maquina.nome.toUpperCase().includes(filter)
            );
        },
        verify_status_sensor() {
            const status = this.appStore.apontamento_atual.state_sensor;
            return status === 'ACTIVE' || status === 'IDLE';
        },
        status_color() {
            const status = this.appStore.maquina_sel.state_sensor;
            if (status === 'ACTIVE') {
                return '#43A047'; // Verde
            } else if (status === 'IDLE') {
                return '#D50000'; // Vermelho
            } else {
                return '#78909C'; // Cinza 
            }
        },

        colorStatusApontamento() {
            if (useAppStore().apontamento_atual.tipo) {
                switch (useAppStore().apontamento_atual.tipo) {
                    case 'Parada':
                        return colorStatusParada(this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup);
                    case 'Produção':
                        return '#43A047';
                    default:
                        return 'primary';
                }
            }
            else {
                return 'primary';
            }

        },
        appStore() {
            return useAppStore();
        },
        isMachineSelected() {
            return (useAppStore().maquina_sel.id != null);
        }
    },
    watch: {
        isMachineSelected(newVal) {
            if (!newVal) {
                this.isMenuOpen = true;
            }
        }
    },
    mounted() {
        if (!this.isMachineSelected) {
            this.isMenuOpen = true;
        }
        else {
            this.inicia_count_machine();
        }
    },
    methods: {
        inicia_count_machine() {
            clearInterval(this.time);
            setInterval(() => {
                this.tempoApontamento()
                this.time++;
            }, 1000);
        },

        async ShowDetalheOS() {
            await useAppOrdemProducao().listar_files_ordem_producao(useAppStore().apontamento_atual.op);
            useAppStore().showModalObsDetalhes = true;
        },
        SelecionarMaquina(maquina) {
            useAppStore().selecionar_maquina(maquina);
            this.inicia_count_machine();
            this.isMenuOpen = false;
        },
        tempoApontamento() {
            this.tempExecucao = CalculaTempoMaquina(new Date(useAppStore().apontamento_atual.dt_inicio)).tempo
        },
    },
};
</script>

<style></style>