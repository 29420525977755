<template>
    <div class="infoprod pt-0 pb-0">
        <v-row justify="center" class="mt-1">
            <small style="font-weight: bold;font-size: 20px;color:#0D47A1;">INFORMAÇÕES DA PRODUÇÃO:</small>
        </v-row>

        <v-row class="py-3 px-5 ga-0">
            <v-col cols="6" class="m-0 p-1">
                <div style="display: flex; align-items: center;">
                    <v-text-field density="compact" class="mb-0 pb-0" label="OPERADOR"
                        v-model="appStore.apontamento_atual.funcionario.nome" readonly
                        style="font-weight: bold;width: 90%;" id="teste" placeholder="Não Informado"
                        persistent-placeholder append-inner-icon="mdi-close"
                        @click:append-inner="ClearFuncionario"></v-text-field>
                    <v-btn size="40" @click="ShowFuncionarios" class="mb-5 ml-2"
                        style="width: 10%;font-size: 25px; min-width: 40px;" color="secondary"
                        append-icon="mdi-magnify"></v-btn>
                </div>
            </v-col>

            <v-col cols="6" class="m-0 p-1">
                <div style="display: flex; align-items: center;">
                    <v-text-field density="compact" label="OBSERVAÇÃO" v-model="appStore.apontamento_atual.observacao"
                        readonly style="font-weight: bold;width: 90%;" id="input_obs" placeholder="Não Informado"
                        persistent-placeholder append-inner-icon="mdi-close"
                        @click:append-inner="ClearObs"></v-text-field>
                    <v-btn size="40" class="mb-5 ml-2" style="width: 10%;font-size: 25px; min-width: 40px;"
                        color="secondary" @click="ShowObs" append-icon="mdi-file-edit-outline"></v-btn>
                </div>
            </v-col>

            <v-col cols="6" class="m-0 p-1" v-if="appStore.apontamento_atual.tipo == 'Parada'">
                <div style="display: flex; align-items: center;">
                    <v-text-field density="compact" bg-color="#D50000"
                        :label="(appStore.apontamento_atual.tipo == 'Parada') && (appStore.apontamento_atual.desc_motivo_parada == null) ? 'INFORMAR O MOTIVO DE PARADA' : 'MOTIVO PARADA'"
                        readonly v-model="appStore.apontamento_atual.desc_motivo_parada"
                        style="font-weight: bold;width: 90%;" placeholder="Não Informado" persistent-placeholder
                        append-inner-icon="mdi-close" @click:append-inner="ClearMotivoParada"></v-text-field>
                      <v-btn v-if="appStore.apontamento_atual.motivo_de_parada_manutencao_setup !== 'Setup'"
                           size="40" class="mb-5 ml-2" @click="ShowMp"
                           style="width: 10%;font-size: 25px; min-width: 40px;" color="secondary"
                           append-icon="mdi-magnify"></v-btn>
                </div>
            </v-col>

            <v-col cols="6" class="m-0 p-1">
                <div style="display: flex; align-items: center;">
                    <v-text-field density="compact" label="ORDEM DE PRODUÇÃO" readonly
                        v-model="appStore.apontamento_atual.op" style="font-weight: bold;width: 90%;" id="teste2"
                        placeholder="Não Informado" persistent-placeholder append-inner-icon="mdi-close"
                        @click:append-inner="ClearOP"></v-text-field>
                    <v-btn size="40" class="mb-5 ml-2" @click="ShowOP"
                        style="width: 10%;font-size: 25px; min-width: 40px;" color="secondary"
                        append-icon="mdi-magnify"></v-btn>
                </div>
            </v-col>

        </v-row>
    </div>
</template>

<script>
import { useAppFuncionarios } from '../../stores/appFuncionarios';
import { useAppOrdemProducao } from '../../stores/appOrdemProducao';
import { useAppApontamento } from '../../stores/appApontamento';
import { useAppStore } from '../../stores/app';
import { useAppMotivoDeParada } from '../../stores/appMotivosdeParada';

export default {
    name: "infoprodApontamento",
    data() {
        return {
            isMenuOpen: false,
            filter_funcionario: '',
        }
    },
    methods: {
        ShowFuncionarios() {
            useAppFuncionarios().showModal = true;
        },
        SelecionarFuncionario(funcionario) {
            const json_update = { operador: funcionario.name }
            useAppStore().selecionar_funcionario(funcionario);
            useAppApontamento().update_apontamento(useAppStore().apontamento_atual.name, json_update)
            this.isMenuOpen = false;
        },

        ShowOP() {
            useAppStore().view_apontamento_historico = false;
            useAppOrdemProducao().showModal = true;
        },

        ShowObs() {
            useAppStore().view_apontamento_historico = false;
            useAppApontamento().observacao_modal = useAppStore().apontamento_atual.observacao;
            useAppApontamento().showModalObs = true;
        },
        async ShowMp() {
            console.log('MP info', useAppApontamento().resourceApontamento.motivo_de_parada_manutencao_setup)
            useAppMotivoDeParada().apontamento_name = this.appStore.apontamento_atual.name;
            await useAppMotivoDeParada().listar_motivos_de_parada();
           
            useAppStore().showModalMotivoDeParada = true;
        },
        ClearOP() {
            useAppApontamento().update_apontamento(useAppStore().apontamento_atual.name, { ordem_de_producao: null })
            this.appStore.apontamento_atual.op = null
        },
        ClearFuncionario() {
            console.log('testehdajksdha 01', useAppApontamento().update_apontamento(useAppStore().apontamento_atual.name, { operador: null }))
            this.appStore.apontamento_atual.funcionario.nome = null
        },
        ClearObs() {
            useAppApontamento().update_apontamento(useAppStore().apontamento_atual.name, { observacao: null })
            this.appStore.apontamento_atual.observacao = null
        },
        ClearMotivoParada() {
            useAppApontamento().update_apontamento(useAppStore().apontamento_atual.name, { motivo_de_parada : null })
            this.appStore.apontamento_atual.desc_motivo_parada = null
        }
    },
    computed: {
        appStore() {
            return useAppStore();
        },
        appApontamento() {
            return useAppApontamento();
        },
        appOrdemProducao() {
            return useAppOrdemProducao();
        }
    },
}
</script>

<style></style>