import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = { style: {"font-weight":"bold","font-size":"15px"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_nuxt_link = _resolveComponent("nuxt-link")
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")
  const _component_v_checkbox = _resolveComponent("v-checkbox")
  const _component_ejs_daterangepicker = _resolveComponent("ejs-daterangepicker")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")
  const _component_e_column = _resolveComponent("e-column")
  const _component_e_columns = _resolveComponent("e-columns")
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_ejs_grid = _resolveComponent("ejs-grid")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_container = _resolveComponent("v-container")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: $options.appStore.showModalHistorico,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($options.appStore.showModalHistorico) = $event)),
    transition: "dialog-bottom-transition",
    fullscreen: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar, { color: "primary" }),
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, {
            fluid: "",
            class: "mt-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_toolbar, {
                        height: "100",
                        dark: "",
                        color: "#37474F"
                      }, {
                        append: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            text: "PESQUISAR",
                            size: "x-large",
                            id: "btn-pesquisar-historico-passados",
                            variant: "flat",
                            class: "ml-3 mr-2",
                            width: "200",
                            color: "secondary",
                            onClick: $options.OnSearchHistoricos
                          }, null, 8 /* PROPS */, ["onClick"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_nuxt_link, {
                            style: {"margin-left":"5px"},
                            to: "/production"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                onClick: _cache[0] || (_cache[0] = $event => ($options.onShowHistorico())),
                                id: "btn-voltar-historicos-passados",
                                color: "#EEEEEE",
                                variant: "flat",
                                size: "x-large",
                                text: ""
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" VOLTAR ")
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createVNode(_component_v_toolbar_title, { id: "title-historicos-passados" }, {
                            default: _withCtx(() => [
                              _createTextVNode("HISTÓRICOS")
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createElementVNode("div", null, [
                            _createVNode(_component_v_checkbox, {
                              style: {"color":"white","font-weight":"bold"},
                              class: "mt-5 pt-0 pb-0 mr-4",
                              label: "FALTANDO INFORMAÇÕES",
                              modelValue: $data.filter_apontamentos_faltando_informacoes,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.filter_apontamentos_faltando_informacoes) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _createElementVNode("div", null, [
                            _createVNode(_component_ejs_daterangepicker, {
                              style: {"height":"40px","font-size":"16px","font-weight":"600","width":"100%"},
                              format: 'dd/MM/yyyy',
                              value: $data.defaultDateRange,
                              onChange: $options.onDateChange
                            }, null, 8 /* PROPS */, ["value", "onChange"])
                          ])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ejs_grid, {
                            dataSource: $options.appStore.apontamentos_historico,
                            allowPaging: true,
                            allowSorting: false,
                            allowFiltering: true,
                            enableHover: false,
                            enableVirtualization: false,
                            enableHeaderFocus: false,
                            rowDataBound: _ctx.onRowDataBound
                          }, {
                            ordem_producao: _withCtx(({ data }) => [
                              _createElementVNode("div", _hoisted_1, [
                                _createVNode(_component_v_btn, {
                                  variant: "text",
                                  size: "35",
                                  onClick: $event => ($options.ShowOrdemDeProducaoHistorico(data)),
                                  color: "secondary",
                                  class: "mr-2"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, {
                                      color: "white",
                                      size: "35"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("mdi-magnify")
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                                (data.ordem_de_producao)
                                  ? (_openBlock(), _createBlock(_component_v_btn, {
                                      key: 0,
                                      variant: "text",
                                      size: "35",
                                      onClick: $event => ($options.ShowDetalheOS(data)),
                                      color: "secondary",
                                      class: "ml-2 mr-2"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, {
                                          color: "white",
                                          size: "35"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-folder-open")
                                          ]),
                                          _: 1 /* STABLE */
                                        })
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                                  : _createCommentVNode("v-if", true),
                                _createElementVNode("small", _hoisted_2, _toDisplayString(data.ordem_de_producao), 1 /* TEXT */)
                              ])
                            ]),
                            inicioTemplate: _withCtx(({ data }) => [
                              _createElementVNode("div", null, _toDisplayString($options.retornaDataFormatada(data.dt_inicio)), 1 /* TEXT */)
                            ]),
                            fimTemplate: _withCtx(({ data }) => [
                              _createElementVNode("div", null, _toDisplayString($options.retornaDataFormatada(data.dt_fim)), 1 /* TEXT */)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_e_columns, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_e_column, {
                                    headerText: "STATUS DO SENSOR",
                                    field: "status_sensor",
                                    width: "80",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "APONTAMENTO",
                                    field: "name",
                                    width: "80",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "STATUS DA PRODUÇÃO",
                                    field: "tipo",
                                    width: "80",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "INICIO",
                                    width: "100",
                                    template: 'inicioTemplate',
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "FIM",
                                    width: "100",
                                    template: 'fimTemplate',
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "OP",
                                    width: "100",
                                    template: 'ordem_producao',
                                    field: "ordem_de_producao",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "TURNO",
                                    width: "100",
                                    field: "turno",
                                    textAlign: "Center"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "MOTIVO DE PARADA",
                                    width: "100",
                                    field: "desc_motivo_parada",
                                    textAlign: "Left"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "FUNCIONARIO",
                                    width: "100",
                                    field: "nome_operador",
                                    textAlign: "Left"
                                  }),
                                  _createVNode(_component_e_column, {
                                    headerText: "OBSERVAÇÃO",
                                    width: "100",
                                    field: "observacao",
                                    textAlign: "Left"
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["dataSource", "rowDataBound"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}