import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_v_row = _resolveComponent("v-row")
  const _component_ejs_pivotview = _resolveComponent("ejs-pivotview")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: $options.appCharts.showModalMp,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($options.appCharts.showModalMp) = $event)),
    transition: "dialog-bottom-transition",
    persistent: "",
    fullscreen: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                onClick: _cache[0] || (_cache[0] = $event => ($options.appCharts.showModalMp = false)),
                color: "secondary",
                variant: "elevated",
                "prepend-icon": "mdi-arrow-left-circle"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("VOLTAR")
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("MOTIVO DE PARADAS")
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_spacer)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                block: "",
                color: "secondary",
                onClick: $options.teste_render
              }, {
                default: _withCtx(() => [
                  _createTextVNode("TESTE")
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["onClick"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_row, { class: "pt-1 pr-0 pl-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                class: "pt-2 pr-1 pl-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ejs_pivotview, {
                    id: "pivotview",
                    ref: "pivotview",
                    dataSourceSettings: $data.dataSourceSettings,
                    enableVirtualization: true,
                    gridSettings: $data.gridSettings,
                    height: $data.height,
                    width: $data.width,
                    allowExcelExport: $data.allowExcelExport,
                    allowConditionalFormatting: $data.allowConditionalFormatting,
                    allowPdfExport: $data.allowPdfExport,
                    showToolbar: $data.showToolbar,
                    allowNumberFormatting: $data.allowNumberFormatting,
                    enablePersistence: true,
                    enableValueSorting: $data.enableValueSorting,
                    allowCalculatedField: $data.allowCalculatedField,
                    showFieldList: $data.showFieldList,
                    toolbar: $data.toolbar,
                    showGroupingBar: $data.showGroupingBar,
                    saveReport: $options.saveReport,
                    loadReport: $options.loadReport,
                    fetchReport: $options.fetchReport,
                    renameReport: $options.renameReport,
                    removeReport: $options.removeReport,
                    newReport: $options.newReport
                  }, null, 8 /* PROPS */, ["dataSourceSettings", "gridSettings", "height", "width", "allowExcelExport", "allowConditionalFormatting", "allowPdfExport", "showToolbar", "allowNumberFormatting", "enableValueSorting", "allowCalculatedField", "showFieldList", "toolbar", "showGroupingBar", "saveReport", "loadReport", "fetchReport", "renameReport", "removeReport", "newReport"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}