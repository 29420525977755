import { MotivoDeParada } from './../app/common/interfaceApp';
import { defineStore } from 'pinia'

export const useAppMotivoDeParada = defineStore({
    id: 'appMotivosdeParada',
    state: (): {
        resourceMotivosDeParada : MotivoDeParada[],
        apontamento_name : Number | null, 
    } => ({
        resourceMotivosDeParada : [],
        apontamento_name : null
    }),
    
    
    actions: {
      
        async get_mp_setup_manutencao(tipo : any) {
          const res = await frappe.call(
            "nxlite.nx_producao.page.apontamento_iot.controller.get_all_motivos_de_parada",{
              tp_Parada : tipo
            });
            if (res.message.length > 0) {
               return {
                name : res.message[0].name,
                descricao : res.message[0].descricao,
               }
              
            }
          },

    async listar_motivos_de_parada(tp_parada: String) {
      try {
        this.resourceMotivosDeParada = [];


        const res = await frappe.call(
          "nxlite.nx_producao.page.apontamento_iot.controller.get_all_motivos_de_parada", {
          tp_Parada: tp_parada
        });

        res.message.forEach((motivos_de_parada: any) => {
          this.resourceMotivosDeParada.push({
            name: motivos_de_parada.name,
            descricao: motivos_de_parada.descricao,
            planejada: motivos_de_parada.planejada,
            setup: motivos_de_parada.setup
          });
        });
      } catch (error) { throw new Error(error); }
    }
  }
})
