<template>
    <v-dialog v-model="appCharts.showModalMp" transition="dialog-bottom-transition" persistent fullscreen>
        <v-card>
            <v-toolbar color="primary">
                <v-btn @click="appCharts.showModalMp = false" color="secondary" variant="elevated"
                    prepend-icon="mdi-arrow-left-circle">VOLTAR</v-btn>

                <v-toolbar-title>MOTIVO DE PARADAS</v-toolbar-title>

                <v-spacer></v-spacer>
            </v-toolbar>
            <v-row>
                <v-btn block color="secondary" @click="teste_render">TESTE</v-btn>
            </v-row>
            <v-row class="pt-1 pr-0 pl-0">
                <v-col cols="12" class="pt-2 pr-1 pl-1">
                    <ejs-pivotview id="pivotview" ref="pivotview" :dataSourceSettings="dataSourceSettings"
                        :enableVirtualization="true" :gridSettings="gridSettings" :height="height" :width="width"
                        :allowExcelExport="allowExcelExport" :allowConditionalFormatting="allowConditionalFormatting"
                        :allowPdfExport="allowPdfExport" :showToolbar="showToolbar"
                        :allowNumberFormatting="allowNumberFormatting" :enablePersistence="true"
                        :enableValueSorting="enableValueSorting" :allowCalculatedField="allowCalculatedField"
                        :showFieldList="showFieldList" :toolbar="toolbar" :showGroupingBar="showGroupingBar"
                        :saveReport="saveReport" :loadReport="loadReport" :fetchReport="fetchReport"
                        :renameReport="renameReport" :removeReport="removeReport"
                        :newReport="newReport"></ejs-pivotview>
                </v-col>

            </v-row>
        </v-card>
    </v-dialog>

</template>

<script>

import {
    GroupingBar,
    FieldList,
    CalculatedField,
    PDFExport,
    ExcelExport,
    ConditionalFormatting,
    NumberFormatting,
    Grouping,
    DrillThrough,
    PivotViewComponent,
    Toolbar
} from "@syncfusion/ej2-vue-pivotview";

import { useAppCharts } from "../../../stores/appCharts";

export default {
    name: "charts_motivo_parada",
    components: {
        "ejs-pivotview": PivotViewComponent
    },
    data() {
        return {
            interval: null,
            showpivot: false,
            height: '100vh',
            width: '100%',
         
            gridSettings: { columnWidth: 100 },
            allowExcelExport: true,
            allowConditionalFormatting: true,
            allowNumberFormatting: true,
            allowPdfExport: true,
            showToolbar: true,
            allowCalculatedField: true,
            showFieldList: true,
            showGroupingBar: true,
            enableValueSorting: true,
            dataSourceSettings: {
                dataSource: [],
                enableSorting: true,
                columns: [
                    { name: 'desc_motivo_parada', caption: 'Motivo de Parada' },
                ],
                values: [
                    { name: 'total_hr', caption: 'Horas totais' },
                ],
                rows: [
                    { name: 'centro_custo', caption: 'Centro de Custo' },
                    { name: 'desc_recurso', caption: 'Recurso:' },
                ],
                filters: [
                ],
                formatSettings: [
                ],
                expandAll: false,
                showSubTotals: true,
                showGrandTotals: true,
                showToolbar: true,
                showFieldList: true,
                allowCalculatedField: true,
                enableVirtualization: false,
                enableFieldSearching: true,
                enablePersistence: false,
                toolbar: [
                    "New",
                    "Save",
                    "SaveAs",
                    "Rename",
                    "Remove",
                    "Load",
                    "Grid",
                    "Chart",
                    "Export",
                    "SubTotal",
                    "GrandTotal",
                    "Formatting",
                    "FieldList"
                ],
                emptyCellsTextContent: '0',
                sortSettings: [
                    {
                        name: 'desc_recurso',
                        order: 'Ascending',
                    },
                ],
            },
            toolbar: [
                "New",
                "Save",
                "SaveAs",
                "Rename",
                "Remove",
                "Load",
                "Grid",
                "Chart",
                "Export",
                "SubTotal",
                "GrandTotal",
                "ConditionalFormatting",
                "NumberFormatting",
                "FieldList"
            ],
            reactiveDataSource: false, // New reactive property
        };
    },
    provide: {
        pivotview: [FieldList,
            CalculatedField,
            PDFExport,
            ExcelExport,
            ConditionalFormatting,
            NumberFormatting,
            GroupingBar,
            Grouping,
            DrillThrough, Toolbar]
    },
    mounted () {
       
    },
    methods: {
        teste_render() {
            console.log('teste_render', this.resourceMp)
            const teste = this.resourceMp

            this.dataSourceSettings = {
                ...this.dataSourceSettings,
                dataSource: teste
            };
        },
        saveReport() {
            console.log('saveReport')
        },
        loadReport() {
            console.log('loadReport')
        },
        fetchReport() {
            console.log('fetchReport')
        },
        renameReport() {
            console.log('renameReport')
        },
        removeReport() {
            console.log('removeReport')
        },
        newReport() {
            console.log('newReport')
        },
    },
    watch: {
        'appCharts.showModalMp'(value) {

        }
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },

        resourceMp() {
            return useAppCharts().resourceMp
        },

    }
}

</script>

<style>
.teste {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>