<template>
    <div class="buttons">
        <v-row justify="center" class="mt-1">
            <small style="font-weight: bold;font-size: 20px;color:#0D47A1">ALTERAR STATUS DA PRODUÇÃO PARA:</small>
        </v-row>

        <v-row class="pr-0 pl-1 pt-0 pb-3 ga-0" v-if="(appStore.apontamento_atual.tipo == 'Parada') || (!appStore.apontamento_atual.name)">
            <v-col cols="6">
                <v-btn id="btn-style" color="#43A047" height="45" stacked size="small" @click="click_produzindo">
                    PRODUZINDO
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn id="btn-style" color="#1E88E5" height="45" stacked size="small" @click="click_manutencao">
                    MANUTENÇÃO
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn id="btn-style" color="#F9A825" height="45" stacked size="small" @click="click_setup">
                    SETUP
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-else class="pr-0 pl-1 pt-0 pb-3 ga-0">
            <v-col cols="6">
                <v-btn id="btn-style" color="#D50000" height="45" stacked size="small" @click="motivo_de_parada">
                    PARADO
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn id="btn-style" color="#1E88E5" height="45" stacked size="small" @click="click_manutencao">
                    MANUTENÇÃO
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn id="btn-style" color="#F9A825" height="45" stacked size="small" @click="click_setup">
                    SETUP
                </v-btn>
            </v-col>
        </v-row> 
    </div>
</template>
<script>

import { useAppStore } from '../../stores/app';

import { useAppApontamento } from '../../stores/appApontamento';
import { useAppMotivoDeParada } from '../../stores/appMotivosdeParada';

export default {
    name: "eventosApontamento",
    computed: {
        appStore() {
            return useAppStore();
        }
    },
    methods: {
        async click_manutencao() {
            const message = { message: 'MANUTENÇÃO INICIADA', color: 'secondary', showMessage: true }
            this.appStore.apontamento_atual.tipo = 'Parada';
            this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup = 'Manutenção';
            const mp_setup = await useAppMotivoDeParada().get_mp_setup_manutencao(this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup)

            const json_update = {
                tipo: this.appStore.apontamento_atual.tipo,
                motivo_de_parada : mp_setup.name,
            }
            useAppApontamento().update_apontamento(this.appStore.apontamento_atual.name, json_update)
            useAppStore().message =  message
        },
        async click_setup() {
            const message = { message: 'SETUP INICIADO', color: 'secondary', showMessage: true }
            this.appStore.apontamento_atual.tipo = 'Parada';
            this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup = 'Setup';
            const mp_setup = await useAppMotivoDeParada().get_mp_setup_manutencao(this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup)

            const json_update = {
                tipo: this.appStore.apontamento_atual.tipo,
                motivo_de_parada : mp_setup.name,
            }
            
            useAppApontamento().update_apontamento(this.appStore.apontamento_atual.name, json_update)
            useAppStore().message =  message
        },
        async motivo_de_parada() {
            const message = { message: 'PRODUÇÃO PARADA', color: 'secondary', showMessage: true }
            this.appStore.apontamento_atual.tipo = 'Parada';
            this.appStore.apontamento_atual.motivo_de_parada_manutencao_setup = null;

            const json_update = {
                tipo: this.appStore.apontamento_atual.tipo,
                setup : 0,
                manutencao : 0
            }
            
            useAppApontamento().update_apontamento(this.appStore.apontamento_atual.name, json_update)
            useAppStore().message =  message
        },
        click_produzindo() {
            const message = { message: 'PRODUÇÃO INICIADA', color: 'secondary', showMessage: true }
            if (this.appStore.apontamento_atual.name) {
                this.appStore.apontamento_atual.tipo = 'Produção';
                useAppApontamento().update_apontamento(this.appStore.apontamento_atual.name, { tipo: this.appStore.apontamento_atual.tipo })
                useAppStore().message =  message
            }
            else {
                useAppApontamento().abrir_apontamento(this.appStore.maquina_sel.id)
                useAppStore().message =  message
            }
            
        }
    },
}

</script>

<style scoped>
#btn-style {
    width: 98%;
    min-width: 100px;
    color: white;
    font-weight: bold;
}

</style>