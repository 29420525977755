<template>
    <v-dialog v-model="AppStore.showModalMotivoDeParada" transition="dialog-bottom-transition" fullscreen>
        <v-card>
            <v-toolbar color="primary">
                <v-btn @click="AppStore.showModalMotivoDeParada = false" color="secondary" variant="elevated"
                    prepend-icon="mdi-arrow-left-circle">VOLTAR</v-btn>

                <v-toolbar-title style="font-size: 13px;font-weight: bold;">SELECIONE O MOTIVO DE PARADA <v-icon
                        class="ml-2"> mdi-information-outline</v-icon></v-toolbar-title>

                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <small class="mt-1 mb-2 pt-2 pb-2" style="font-weight: bold;font-size: 18px; color: #0D47A1 ">MOTIVOS DE PARADA NÃO
                    PLANEJADA</small>
                <v-row>
                    <v-col v-for="mp, index in resourceMp" :key="index" cols="2">
                        <v-btn @click="OnMpClick(mp)" width="100%" class="ml-1 mr-1 mt-2" color="#D50000" height="100"
                            stacked>{{ mp.descricao }}</v-btn>
                    </v-col>
                </v-row>
                <v-divider class="mt-4"></v-divider>
                <small class="mt-3 mb-2 pt-3 pb-2" style="font-weight: bold;font-size: 18px; color: #0D47A1">MOTIVOS DE PARADA
                    PLANEJADA</small>
                <v-row>
                    <v-col v-for="mp, index in resourceMpPlanejada" :key="index" cols="2">
                        <v-btn width="100%" @click="OnMpClick(mp)" class="ml-1 mr-1 mt-2" color="#D50000" height="100"
                            stacked>{{ mp.descricao }}</v-btn>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import { useAppStore } from '../../../stores/app';

import { useAppMotivoDeParada } from '../../../stores/appMotivosdeParada';

import { useAppApontamento } from '../../../stores/appApontamento';

export default {
    name: 'motivosParada',
    data() {
        return {
            count: 0
        }
    },
    computed: {
        resourceMpPlanejada() {
            return useAppMotivoDeParada().resourceMotivosDeParada.filter(motivo => motivo.planejada == 1);
        },
        resourceMp() {
            return useAppMotivoDeParada().resourceMotivosDeParada.filter(motivo => motivo.planejada == 0);
        },
        AppStore() {
            return useAppStore();
        }
    }, 
    mounted() { },
    methods: {
        async OnMpClick(data) {

            const json_update = {
                tipo: 'Parada',
                motivo_de_parada: data.name
            }
         
            const res = await useAppApontamento().update_apontamento(useAppMotivoDeParada().apontamento_name, json_update)

            if (useAppStore().view_apontamento_historico) {
                useAppApontamento().resourceApontamento.desc_motivo_parada = res.message.desc_motivo_parada
                useAppApontamento().resourceApontamento.motivo_de_parada = res.message.motivo_de_parada
            }
            
            const message = { message: `PRODUÇÃO PARADA POR ${res.message.desc_motivo_parada}`, color: 'secondary', showMessage: true }
            
            useAppStore().message = message

            useAppStore().showModalMotivoDeParada = false;
        }
    }
}

</script>

<style></style>